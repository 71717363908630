$basic-blue: #0052A4;
$dark-blue: #1A2A61;
$white: #FFFFFF;
$gradient-blue: #4B6DB3;
$gradient-red: #FF2D64;
$gradient-yellow: #FFB143;
$gradient-orange: #FF6E56;
$color-gradient: linear-gradient(90deg, #496DB4 0%, #FF2D64 33.33%, #FF6E56 66.15%, #FFB143 100%);
$blue-gradient: linear-gradient(90deg, #0052A4 0%, #1A2A61 100%);
$half-blue-gradient: linear-gradient(45deg, #0052A4 49.86%, #1A2A61 49.87%);
$half-blue-gradient-hover: linear-gradient(45deg, #1A2A61 51.49%, #0052A4 51.5%);
$gradient-light: linear-gradient(45deg, #FFFFFF 49.86%, #E7F3FF 49.87%);
$gradient-light-hover: linear-gradient(45deg, #E7F3FF 50.45%, #FFFFFF 50.46%);
$dark-grey: #131B39;
$light-grey: #F7F8FD;
$light-blue: #B3CBE4;
$menu-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 50%);
$gradient-yellow-20: #FFEFD9;
$light-blue-30: #EBF2FA;
$dark-grey-30: #C2C3CB;
$extra-light-blue: #E7F3FF;
$box-shadow: 0px 20px 30px rgba(26, 42, 97, 0.2);
$box-shadow-hover: 10px 30px 50px rgba(11, 70, 151, 0.32);


