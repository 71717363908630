// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
//@import "~@angular/material/theming";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!

@use '@angular/material' as mat;

@include mat.core();

$my-primary: mat.define-palette(mat.$indigo-palette, 500);
$my-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

$my-theme: mat.define-light-theme((
        color: (
                primary: $my-primary,
                accent: $my-accent,
        )
));

@include mat.all-component-themes($my-theme);
/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import "mixins";
@import "variables";
@import "node_modules/bootstrap/scss/bootstrap.scss";

/* reset css */
/*
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com
Twitter: @rich_clark
*/

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  outline: none;
}

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

body {
  line-height: 1;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input, select {
  vertical-align: middle;
}

//Personal default styling

html {
  font-size: 62.5%;
}

body {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.2rem;
  color: $dark-blue;

  &.bold {
    font-weight: 700;
  }
}

.no-scroll {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

app-navbar {
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 100;

  .navbar {
    padding: 0;
  }
}

app-cookie {
  position: fixed;
  z-index: 101;

  @include media-breakpoint-down(md) {
    top: 50%;
    left: 2rem;
    transform: translateY(-50%);
    width: calc(100vw - 4rem);
  }
  @include media-breakpoint-up(md) {
    bottom: 0;
    width: 100%;
  }


  &.more {
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    transform: none;
    height: 100%;
    width: 100%;
  }
}

//Headings:

h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 2.8rem;
  line-height: 3.8rem;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: $dark-blue;
  @include media-breakpoint-up(md) {
    font-size: 3.2rem;
    line-height: 3.6rem;
  }
  @include media-breakpoint-up(xl) {
    font-size: 4.4rem;
    line-height: 5rem;
    letter-spacing: 0.05em;
  }
}

h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 2.8rem;
  line-height: 3.8rem;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: $dark-blue;
  @include media-breakpoint-up(md) {
    font-size: 3.2rem;
    line-height: 3.6rem;
  }
}

h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 2.8rem;
  line-height: 3.8rem;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: $dark-blue;

}

h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 2.2rem;
  line-height: 2.6rem;
  color: $basic-blue;

  &.bold {
    font-weight: 700;
  }
}

h5 {
  font-style: normal;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: $dark-blue;

  &.light {
    font-weight: 300;
  }

  &.semi {
    font-weight: 600;
  }

  &.bold {
    font-weight: 700;
  }
}

p {
  &:not(.no-margin) {
    margin-bottom: 2.2rem;
  }

  &.margin-large {
    margin-bottom: 2.5rem;
    @include media-breakpoint-up(xl) {
      margin-bottom: 5rem;
    }
  }

  &.bold {
    font-weight: 700;
  }
}

span {
  &.bold {
    font-weight: 700;
  }
}

a {
  color: currentColor;
  text-decoration: none;
}

strong {
  font-weight: 700;
}

// reset bootstrap default style
.card {
  border: none;
}


.content {
  &-container {
    width: 100%;
    max-width: 136.6rem;
    margin: 0 auto;
    padding: 0 1.8rem;

    @include media-breakpoint-up(xl) {
      padding: 0 8rem;
    }
  }

  // static page headings
  &--top {
    padding-top: 14rem;
    @include media-breakpoint-up(md) {
      padding-top: 9.2rem;
    }

    &__title {
      position: relative;
      height: 13.8rem;

      h1 {
        color: $white;
        @include media-breakpoint-up(xl) {
          position: absolute;
          top: 4rem;
        }
      }

      @include media-breakpoint-up(xl) {
        height: 25.1rem;
      }

      &::before {
        position: absolute;
        top: calc(-6vw - 9.2rem);
        right: 0;
        bottom: 0;
        left: 0;
        height: calc(100% + 9.2rem);
        content: "";
        transform: skewY(-6.5deg);
        z-index: -1;
        background-color: $basic-blue;

        @include media-breakpoint-up(xl) {
          top: calc(-4vw - 12.4rem);
        }
      }
    }

    &__lead {
      position: relative;
      height: 99rem;

      @include media-breakpoint-up(md) {
        height: 50rem;
      }

      @include media-breakpoint-down(md) {
        padding-top: 3.6rem;
      }

      .content-container {
        position: relative;
      }

      &__container {
        display: grid;
        grid-template-columns: 100%;
        grid-auto-rows: auto auto auto;

        @include media-breakpoint-up(md) {
          position: absolute;
          top: -3rem;
          grid-template-columns: auto auto;
          grid-template-rows: auto 1fr;
        }

        @include media-breakpoint-up(xl) {
          top: -5rem;
          width: calc(100% - 16rem);
        }

        @include media-breakpoint-up(xxl) {
          top: -7rem;
        }
      }

      &::before {
        position: absolute;
        top: -6vw;
        right: 0;
        bottom: 0;
        left: 0;
        height: 110%;
        content: "";
        transform: skewY(-6.5deg);
        z-index: -1;
        background-color: $extra-light-blue;
      }

      &__image {
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        width: 22.2rem;
        height: 22.2rem;
        padding: 1rem;
        background-color: $white;
        border: 1px solid $light-blue;
        box-shadow: $box-shadow;
        @include media-breakpoint-down(md) {
          margin-bottom: 3rem;
        }
        @include media-breakpoint-up(md) {
          grid-row: 1 / 3;
          width: 24.2rem;
          height: 24.2rem;
          margin-right: 3rem;
        }
        @include media-breakpoint-up(xl) {
          width: 42.4rem;
          height: 42.4rem;
          padding: 1.7rem;
          margin-right: 8.7rem;
        }

        img {
          width: 100%;
          height: 100%;
        }
      }

      &__title {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        margin-bottom: 3rem;
        @include media-breakpoint-up(md) {
          grid-column: 2 / 3;
        }
        @include media-breakpoint-up(xl) {
          margin-bottom: 5rem;
        }

        h2 {
          @include media-breakpoint-down(md) {
            word-break: break-all;
          }
        }

        &__separator {
          width: 17rem;
          height: 3px;
          margin-top: 1rem;
          background: $color-gradient;
        }
      }

      &__description {
        grid-column: 1 / 2;
        grid-row: 3 / 4;
        @include media-breakpoint-up(md) {
          grid-column: 2 / 3;
          grid-row: 2 / 3;
        }

        &__header {
          text-transform: uppercase;
        }

        &__title {
          font-weight: 700;
        }

        &__list {
          &__title {
            margin-bottom: 0.8rem;
            font-weight: 700;
          }

          &__item {
            display: flex;
            align-items: flex-start;

            &::before {
              display: block;
              content: "";
              background: url("assets/icons/checkmark.svg") center/100% no-repeat;
              width: 1.6rem;
              min-width: 1.6rem;
              height: 1.6rem;
              margin-right: 0.8rem;
              transform: translateY(3px);
            }
          }
        }

        &__information {
          font-style: italic;
        }
      }

      &__navigation {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 3rem;
        padding: 1.8rem 1.6rem;
        font-weight: 700;
        background-color: $white;
        color: $basic-blue;
        border: 1px solid $light-blue;
        @include media-breakpoint-up(xl) {
          margin-top: 5rem;
        }

        &__text {
          display: flex;
          flex-direction: column;

          .main {
            font-size: 2rem;
            line-height: 2.4rem;
            text-transform: uppercase;
          }

          .sub {
            margin-top: 0.8rem;
            font-size: 1.4rem;
            line-height: 1.7rem;
          }
        }

        &__arrow {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 4rem;
          min-width: 4rem;
          height: 4rem;
          background: $half-blue-gradient;

          &:hover {
            background: $half-blue-gradient-hover;
          }
        }
      }
    }
  }

  // static page main content
  &--main {
    margin: 10rem 0 4.2rem;

    @include media-breakpoint-up(md) {
      margin: 5rem 0 7.4rem;
    }

    @include media-breakpoint-up(xl) {
      margin-top: 2rem;
    }

    .content-container {
      max-width: 116.2rem;
      @include media-breakpoint-up(xl) {
        &.wide {
          max-width: 136.6rem;
          padding: 0;
        }
      }
    }

    &__title {
      margin-bottom: 2.4rem;
    }

    &__link {
      display: flex;
      align-items: center;
      font-weight: 700;
      font-size: 1.4rem;
      color: $gradient-yellow;
      letter-spacing: 0.05em;
      text-transform: uppercase;

      img {
        margin-left: 0.8rem;
      }
    }

    &__information {
      margin-bottom: 3rem;
      text-align: left;
      @include media-breakpoint-up(md) {
        margin-bottom: 6rem;
      }
      @include media-breakpoint-up(xl) {
        margin-bottom: 7rem;
      }

      h1 {
        margin-bottom: 1.6rem;
        @include media-breakpoint-up(md) {
          margin-bottom: 2.6rem;
        }
        @include media-breakpoint-up(xl) {
          font-size: 3.2rem;
          line-height: 3.6rem;
        }
      }

      p {
        max-width: 79.4rem;
        margin-left: auto;
        margin-right: auto;
      }

      &__image-container {
        display: flex;
        flex-direction: column;
        @include media-breakpoint-up(md) {
          flex-direction: row;
        }

        .image {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          @include media-breakpoint-up(md) {
            width: 50%;
          }

          img {
            max-width: 100%;
          }

          span {
            display: block;
            margin-top: -3rem;
            font-weight: 700;
            font-size: 1.6rem;
            line-height: 2.2rem;
            color: $basic-blue;
            @include media-breakpoint-up(md) {
              margin-top: -4rem;
            }
            @include media-breakpoint-up(lg) {
              margin-top: -5rem;
            }
          }
        }
      }
    }

    &__content {
      &__text-image {
        display: flex;
        flex-direction: column;

        @include media-breakpoint-up(md) {
          flex-direction: row-reverse;
          justify-content: space-between;
        }

        .image,
        .text {
          @include media-breakpoint-down(md) {
            width: calc(100% + 3rem);
            margin: 0 -1.5rem;
          }
          @include media-breakpoint-up(md) {
            width: calc((100% - 1.8rem) / 2);
          }
        }

        .text {
          @include media-breakpoint-down(md) {
            margin-top: 2.4rem;
          }
        }

        &--detailed {
          @include media-breakpoint-up(md) {
            display: flex;
            justify-content: space-between;
          }

          &.reversed {
            @include media-breakpoint-up(md) {
              flex-direction: row-reverse;
            }
          }

          .images,
          .text {
            width: 100%;
            @include media-breakpoint-up(md) {
              width: calc((100% - 2rem) / 2);
            }
            @include media-breakpoint-up(md) {
              width: calc((100% - 4.8rem) / 2);
            }
          }

          .images {
            @include media-breakpoint-down(md) {
              margin-top: 4rem;
            }

            .image {
              width: 100%;
              max-width: 46rem;
              overflow: hidden;

              & + .image {
                margin-top: 3rem;
                @include media-breakpoint-up(md) {
                  margin-top: 4.8rem;
                }
              }

              img {
                width: 100%;
              }
            }
          }

          .text {
            p {
              &:last-child {
                margin-bottom: 0;
              }
            }

            h4 {
              + p {
                margin-top: 2.4rem;
              }
            }

            ul {
              li {
                list-style-position: inside;

                &::marker {
                  font-size: 60%;
                }
              }
            }

            .list {
              &__title {
                margin-bottom: 0.8rem;
                font-weight: 700;
              }

              &__item {
                display: flex;
                align-items: flex-start;
                font-weight: 500;

                &:not(:first-child) {
                  margin-top: 0.8rem;
                }

                &::before {
                  display: block;
                  content: "";
                  background: url("assets/icons/checkmark.svg") center/100% no-repeat;
                  width: 1.6rem;
                  min-width: 1.6rem;
                  height: 1.6rem;
                  margin-right: 0.8rem;
                }
              }
            }
          }

          .list {
            li {
              list-style-type: disc;
              margin-left: 1rem;
            }
          }

          & + .content--main__content__text-image--detailed {
            margin-top: 7.5rem;
          }
        }
      }

      &__numbered-list {
        &__item {
          position: relative;
          display: flex;

          .dotted-line {
            position: absolute;
            height: 100%;
            border-left: 2px dotted $light-blue;
            top: 4.2rem;
            left: 2rem;
          }

          .number {
            display: flex;
            justify-content: center;
            align-items: center;
            min-width: 4rem;
            height: 4.2rem;
            margin-right: 1.5rem;
            font-weight: 700;
            font-size: 2.2rem;
            line-height: 2.6rem;
            background: $light-blue;
            color: $white;
            border-radius: 100%;
            @include media-breakpoint-up(md) {
              margin-right: 4rem;
            }
          }

          .information {
            &__title {
              margin-bottom: 1.3rem;
              font-weight: 500;
              font-size: 2.2rem;
              line-height: 2.6rem;
              color: $basic-blue;
            }

            &__text {
              &__list {
                li {
                  list-style-type: disc;
                  margin-left: 2.5rem;

                  &:not(:last-child) {
                    margin-bottom: 2.2rem;
                  }

                  &::marker {
                    font-size: 60%;
                  }

                  ul {
                    margin-left: 2rem;
                  }
                }

                &.sublist {
                  margin-left: 2rem;

                  li {
                    margin-bottom: 0;
                  }
                }
              }
            }
          }

          &:not(:first-child) {
            margin-top: 2rem;
            @include media-breakpoint-up(md) {
              margin-top: 3.6rem;
            }
          }

          &:last-child {
            .dotted-line {
              display: none;
            }
          }
        }

        & + p {
          margin-top: 2.5rem;
        }
      }

      &__text-price {
        display: flex;
        flex-direction: column;

        @include media-breakpoint-up(xl) {
          flex-direction: row;
          justify-content: space-between;
        }

        .text,
        .price-card {
          width: 100%;
        }

        .price-card {
          display: flex;
          flex-direction: column;
          border: 1px solid $light-blue;
          border-radius: 4px;
          background-color: $light-blue-30;
          box-shadow: $box-shadow;
          @include media-breakpoint-between(md, xl) {
            flex-direction: row;
            height: 8.4rem;
          }
          @include media-breakpoint-down(xl) {
            margin-top: 2rem;
          }
          @include media-breakpoint-up(xl) {
            width: 23.9rem;
            min-width: 23.9rem;
          }

          .title {
            position: relative;
            padding: 1.6rem 1.6rem 0.9rem;
            color: $white;
            background-color: $gradient-orange;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            font-weight: 500;
            font-size: 1.6rem;
            line-height: 2rem;
            text-align: center;

            @include media-breakpoint-between(md, xl) {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              width: 30.6rem;
              padding: 0 1.2rem;
              text-align: left;
            }

            &-chevron {
              display: flex;
              margin-top: -1px;
              height: 3.1rem;
              width: 100%;

              @include media-breakpoint-between(md, xl) {
                flex-direction: column;
                width: 2.8rem;
                height: 100%;
              }

              .left {
                width: 50%;
                height: 100%;
                background: linear-gradient(to top right, #EBF2FA 50.45%, #FF6E56 50.46%);
                @include media-breakpoint-between(md, xl) {
                  background: linear-gradient(to bottom left, #EBF2FA 50.45%, #FF6E56 50.46%);
                }

              }

              .right {
                width: 50%;
                height: 100%;
                background: linear-gradient(to bottom right, #FF6E56 50.45%, #EBF2FA 50.46%);
              }
            }
          }

          .price-tag {
            @include media-breakpoint-down(md) {
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 0.8rem 0 2.8rem;
              font-size: 3rem;
              line-height: 3rem;
            }
            padding: 0.8rem 4rem 2.8rem;
            font-size: 3.6rem;
            line-height: 3.6rem;
            color: $basic-blue;
            text-align: center;

            .price {
              display: flex;
              justify-content: center;
              align-items: baseline;

              & .pre {
                margin-right: 0.8rem;
                font-weight: 700;
                font-size: 1.6rem;
                line-height: 1.6rem;
                color: $basic-blue;
                @include media-breakpoint-down(md) {
                  margin-left: 0.8rem;
                }
                @include media-breakpoint-between(md, xl) {
                  margin-right: 1.6rem;
                }
              }

              & + .price {
                @include media-breakpoint-between(md, xl) {
                  margin-top: 0;
                  margin-left: 1.6rem;
                }
                @include media-breakpoint-up(xl) {
                  margin-top: 0.8rem;
                }
              }
            }

            @include media-breakpoint-between(md, xl) {
              display: flex;
              justify-content: flex-end;
              align-items: center;
              flex: 1;
              padding: 0 1.2rem;
            }
          }
        }
      }

      &__price-double {
        display: flex;
        flex-direction: column;

        @include media-breakpoint-up(xl) {
          flex-direction: row;
          justify-content: space-between;
        }

        .price-card {
          display: flex;
          flex-direction: column;
          width: 100%;
          border: 1px solid $light-blue;
          border-radius: 4px;
          background-color: $light-blue-30;
          box-shadow: $box-shadow;
          @include media-breakpoint-between(md, xl) {
            flex-direction: row;
            height: 8.4rem;
          }
          @include media-breakpoint-down(xl) {
          }
          @include media-breakpoint-up(xl) {
            width: calc((100% - 4.8rem) / 2);
          }

          .title {
            position: relative;
            padding: 1.6rem 1.6rem 0.9rem;
            color: $white;
            background-color: $gradient-orange;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            font-weight: 500;
            font-size: 1.6rem;
            line-height: 2rem;
            text-align: center;

            @include media-breakpoint-between(md, xl) {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              width: 30.6rem;
              padding: 0 1.2rem;
              text-align: left;
            }

            &-chevron {
              display: flex;
              margin-top: -1px;
              height: 3.1rem;
              width: 100%;

              @include media-breakpoint-between(md, xl) {
                flex-direction: column;
                width: 2.8rem;
                height: 100%;
              }

              .left {
                width: 50%;
                height: 100%;
                background: linear-gradient(to top right, #EBF2FA 50.45%, #FF6E56 50.46%);
                @include media-breakpoint-between(md, xl) {
                  background: linear-gradient(to bottom left, #EBF2FA 50.45%, #FF6E56 50.46%);
                }

              }

              .right {
                width: 50%;
                height: 100%;
                background: linear-gradient(to bottom right, #FF6E56 50.45%, #EBF2FA 50.46%);
              }
            }
          }

          .price-tag {
            padding: 0.8rem 4rem 2.8rem;
            font-size: 3.6rem;
            line-height: 3.6rem;
            color: $basic-blue;
            text-align: center;

            .price {
              display: flex;
              justify-content: center;
              align-items: baseline;

              & .pre {
                margin-right: 0.8rem;
                font-weight: 700;
                font-size: 1.6rem;
                line-height: 1.6rem;
                color: $basic-blue;
                @include media-breakpoint-between(md, xl) {
                  margin-right: 1.6rem;
                }
              }

              & + .price {
                margin-top: 0.8rem;
                @include media-breakpoint-between(md, xl) {
                  margin-top: 0;
                  margin-left: 1.6rem;
                }
              }
            }

            @include media-breakpoint-between(md, xl) {
              display: flex;
              justify-content: flex-end;
              align-items: center;
              flex: 1;
              padding: 0 1.2rem;
            }
          }

          & + .price-card {
            @include media-breakpoint-down(xl) {
              margin-top: 2rem;
            }
          }
        }
      }

      &__list {
        &__title {
          margin-bottom: 0.8rem;
          font-weight: 700;
        }

        &__item {
          display: flex;
          align-items: flex-start;
          font-weight: 500;

          &::before {
            display: block;
            content: "";
            background: url("assets/icons/checkmark.svg") center/100% no-repeat;
            width: 1.6rem;
            min-width: 1.6rem;
            height: 1.6rem;
            margin-right: 0.8rem;
          }

          & + .content--main__content__list__item {
            margin-top: 0.8rem;
          }
        }
      }

    }

    &__table {
      display: grid;
      grid-template-columns: 17.4rem 1fr 1fr;
      gap: 1rem;
      overflow-x: scroll;

      @include media-breakpoint-up(xl) {
        overflow-x: hidden;
      }

      div {
        background-color: $extra-light-blue;
        color: $dark-blue;
        padding: 1.6rem;

        &.header {
          display: flex;
          align-items: center;
          background-color: $gradient-orange;
          color: $white;
          font-weight: 700;
          word-break: break-all;
        }

        ul {
          li {
            list-style-position: inside;

            &::marker {
              font-size: 60%;
            }
          }
        }
      }

      @include media-breakpoint-up(xl) {
        grid-template-columns: 27rem 1fr 1fr;
      }
    }

    app-contact-form {
      display: block !important;
      max-width: 136.6rem;
      margin: 0 auto;
    }
  }

  // static page bottom image
  &--bottom {
    position: relative;
    width: 100%;
    max-width: 136.6rem;
    margin: 0 auto 4rem;
    overflow: hidden;

    @include media-breakpoint-down(md) {
      height: 21rem;
    }
    @include media-breakpoint-up(xl) {
      height: auto;
      margin-bottom: 6rem;
    }

    img {
      @include media-breakpoint-down(sm) {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        height: 100%;
      }
      @include media-breakpoint-up(sm) {
        height: auto;
        width: 100%;
      }
    }
  }

  // accordion
  &__accordions {

    &:last-child {
      margin-bottom: 7rem;
      //border-bottom: 1px solid $light-blue;

      @include media-breakpoint-up(xl) {
        margin-bottom: 10rem;
      }
    }

    app-offer-accordion {
      &:last-child {
        .content__accordion {
          margin-bottom: 4rem;
          border-bottom: 1px solid $light-blue;

          @include media-breakpoint-up(xl) {
            margin-bottom: 7rem;
          }
        }
      }
    }
  }

  &__accordion {
    width: 100%;
    color: $white;
    overflow: hidden;

    &:last-child {
      margin-bottom: 7rem !important;
      border-bottom: 1px solid $light-blue;

      @include media-breakpoint-up(xl) {
        margin-bottom: 10rem !important;
      }
    }

    input {
      position: absolute;
      opacity: 0;
      z-index: -1;
    }

    &__label {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 2.5rem 1.6rem;
      font-weight: 700;
      font-size: 1.8rem;
      line-height: 2.2rem;
      text-transform: uppercase;
      color: $dark-blue;
      background: $white;
      border-top: 1px solid $light-blue;
      cursor: pointer;
      transition: color 0.35s ease;

      img {
        transition: all .35s;
      }
    }

    &__content {
      max-height: 0;
      padding: 0 1.6rem;
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 2.2rem;
      color: $dark-blue;
      background: $white;
      transition: all .35s;

      h4 {
        &.top-margin {
          margin-top: 5rem;
        }

        & + p {
          margin-top: 2.4rem;
        }
      }

      &__title {
        color: $basic-blue;
      }

      &__list {
        margin-top: 4rem;

        ul {
          li {
            margin-left: 2.5rem;

            &::marker {
              font-size: 60%;
            }
          }
        }
      }

      .content--main__content__list {
        & + * {
          margin-top: 2.5rem;
        }
      }
    }

    input:checked {
      + .content__accordion__label {
        color: $gradient-orange;

        img {
          transform: rotate(180deg);
          transition: all .35s;
        }
      }

      ~ .content__accordion__content {
        max-height: unset;
        padding: 0.5rem 1.6rem 5.2rem;

      }
    }
  }

}

// navigation button styling

.navigation-button {
  display: flex;
  align-items: center;
  width: fit-content;
  margin-top: 2.5rem;

  &__label {
    padding: 1.2rem 3.2rem;
    font-weight: 700;
    font-size: 1.4rem;
    line-height: 1.6rem;
    background-color: $basic-blue;
    color: $white;
    text-transform: uppercase;
  }

  &__arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4rem;
    height: 4rem;
    margin-left: 2px;
    background: $half-blue-gradient;
  }

  &:hover {
    .navigation-button {

      &__label {
        background-color: $dark-blue;
      }

      &__arrow {
        background: $half-blue-gradient-hover;
      }
    }
  }

  &.inverted {
    .navigation-button {
      &__label {
        background-color: $white;
        color: $gradient-blue;
      }

      &__arrow {
        background: $gradient-light;
        color: $gradient-blue;
      }
    }

    &:hover {
      .navigation-button {
        &__label {
          background-color: $extra-light-blue;
        }

        &__arrow {
          background: $gradient-light-hover;
        }
      }
    }

  }
}

//carousel arrows

.content--gallery,
.homepage__offers,
.homepage__reviews,
.homepage__news {
  .carousel {
    z-index: unset;

    .carousel-arrow {
      background-color: $basic-blue !important;
      box-shadow: none !important;
      opacity: 1 !important;
      width: 6rem !important;
      height: 10.8rem !important;
      border-radius: 3px 0px 0px 3px !important;
      background-size: 100% 100% !important;

      &:hover {
        cursor: pointer;
      }

      &-prev {
        left: -8rem !important;
        background-image: url("assets/icons/carousel-prev.svg") !important;
      }

      &-next {
        right: -8rem !important;
        background-image: url("assets/icons/carousel-next.svg") !important;
      }
    }
  }
}

.homepage__offers {
  .carousel {
    .carousel-arrow {
      &-prev {
        @include media-breakpoint-down(xl) {
          left: -2rem !important;
        }
      }

      &-next {
        @include media-breakpoint-down(xl) {
          right: -2rem !important;
        }
      }
    }
  }
}

.homepage__gallery {
  .carousel {
    .carousel-container {
      .carousel-cells {
        .carousel-cell {
          display: flex;
          justify-content: center;

          a {
            display: block;
            width: 22.9rem;
            height: 17rem;

            &.text {
              border: 1px solid $light-blue;

              .more-galeries {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
                font-weight: 700;
                font-size: 1.4rem;
                line-height: 1.7rem;
                letter-spacing: 0.05em;
                text-transform: uppercase;
                color: $gradient-yellow;
              }
            }
          }
        }
      }

      .carousel-dots {

      }
    }
  }
}

// dialog
.dark-backdrop {
  background-color: $basic-blue;
  opacity: 0.9 !important;
}

.dialog-panel {
  max-width: calc(100vw - 3.2rem) !important;
  @include media-breakpoint-up(md) {
    max-width: calc(100vw - 16rem) !important;
  }

  @include media-breakpoint-up(xl) {
    max-width: 100.2rem !important;
  }

  .mat-dialog-container {
    padding: 0;
    overflow: hidden;
    box-shadow: none !important;
    background-color: transparent !important;

    .carousel-inner {
      .carousel-item {
        align-items: center;
        justify-content: center;
        height: 79vh;
      }
    }
  }
}

app-gallery-dialog {
  .carousel-indicators {
    display: none;
  }

  .carousel-control-prev {
    width: 5rem;
    left: 0;
  }

  .carousel-control-next {
    width: 5rem;
    right: 0;
  }
}

.content--main__opening {
  p {
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

#actualInformation {
  max-height: 0;
  overflow: hidden;
  transition: max-height .75s;
}

ngb-carousel {
  max-width: 100%;
}

ngb-carousel img {
  width: 100%;
  height: 100%;
  outline: none;
}

ngb-carousel {
  width: inherit;
  height: inherit;
}

.carousel-inner {
  overflow: hidden;
}

.carousel-item {
  display: flex !important;
  opacity: 0;
  visibility: hidden;
  z-index: -1;
}

.carousel-item.active {
  opacity: 1;
  visibility: visible;
  z-index: 10;
}

.carousel-control-prev {
  z-index: 20;
}

.carousel-control-next {
  z-index: 20;
}

.carousel-indicators {
  z-index: 20;
}

ul {
  margin-bottom: 1.6rem;

  &.circle {
    li {
      display: flex;
      align-items: flex-start;
      list-style: none;

      &::before {
        display: block;
        content: "";
        background: url("/assets/icons/checkmark.svg") center/100% no-repeat;
        width: 1.6rem;
        min-width: 1.6rem;
        height: 2.2rem;
        margin-right: 0.8rem;
      }
    }
  }

  &.disc {
    li {
      list-style-position: inside;

      &::marker {
        font-size: 60%;
      }
    }
  }
}

ol {
  margin-bottom: 1.6rem;

  &.decimal {
    li {
      list-style-position: inside;
      list-style-type: none;
      padding: 0;

      &::before {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        content: counter(list-item) "\a0\a0";
        width: 2.7rem;
        height: 2.7rem;
        margin-right: 1.2rem;
        padding-left: 0.9rem;
        font-weight: 700;
        font-size: 1.6rem;
        line-height: 2.2rem;
        background-color: $gradient-orange;
        color: $white;
        text-align: center;
      }

      & + li {
        margin-top: 0.8rem;
      }
    }
  }

  &.upper-roman {
    li {
      position: relative;
      list-style-type: none;
      margin-top: 4.5rem;
      padding: 0 0 0 5.5rem;
      z-index: 0;
      @include media-breakpoint-up(md) {
        padding: 0 0 0 8rem;
      }

      &::before {
        content: counter(list-item) "\a0\a0";
        position: absolute;
        left: 0;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 4rem;
        height: 4.2rem;
        padding-left: 1.2rem;
        font-weight: 700;
        font-size: 2.2rem;
        line-height: 2.6rem;
        background: $light-blue;
        color: $white;
        border-radius: 100%;
        z-index: 1;
      }

      span {
        &::before {
          content: "";
          display: inline-block;
          position: absolute;
          height: calc(100% + 4.5rem);
          min-height: 9rem;
          border-left: 2px dotted $light-blue;
          top: 0rem;
          left: 2rem;
        }
      }

      &:last-child {
        span {
          &::before {
            display: none;
          }
        }
      }
    }
  }
}
